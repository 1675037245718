import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { KeyTextField } from "@prismicio/types";
import { LogomarkWithNameBlue } from "@/assets/vectors/logo";
import { SocialIcon } from "@/components/Icons/Social";
import { Separator } from "@/components/Separator";
import Link from "next/link";

/**
 * Props for `FooterStaticSlice`.
 */
export type FooterStaticSliceProps =
    SliceComponentProps<Content.FooterStaticSliceSlice>;

/**
 * Component for "FooterStaticSlice" Slices.
 */
const FooterStaticSlice = ({ slice }: FooterStaticSliceProps): JSX.Element => (
    <section
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        className="flex w-full flex-col items-center px-4 lg:max-w-[1056px] lg:flex-row lg:justify-between lg:pt-6"
    >
        <div className="hidden lg:contents">
            <CopyrightDiv text={slice.primary.copyright} />
        </div>
        <div className="flex gap-2">
            {slice.primary.social.map((s) => {
                if (!("url" in s.path) || typeof window === "undefined")
                    return <></>;
                const child = s.name ? (
                    <SocialIcon type={s.name} className="size-6 text-primary" />
                ) : (
                    <></>
                );
                if (!s.path.url) return <div key={s.name}>{child}</div>;
                const link = new URL(s.path.url ?? "", window.location.origin);
                const isSameSite = link.host === window.location.host;
                if (isSameSite)
                    return (
                        <Link key={s.name} href={link}>
                            <a className="remove-styles-a">{child}</a>
                        </Link>
                    );
                return (
                    <a key={s.name} href={s.path.url} target="_blank">
                        {child}
                    </a>
                );
            })}
        </div>
        <Separator color="light" className="mb-4 mt-8 lg:hidden" />
        <div className="flex flex-col items-center gap-y-5 lg:hidden">
            <LogomarkWithNameBlue />
            <CopyrightDiv text={slice.primary.copyright} />
        </div>
    </section>
);

const CopyrightDiv = ({ text }: { text: KeyTextField }): JSX.Element => (
    <span className="typography-sub text-center text-blue-grey-400 lg:text-left">
        {text}
    </span>
);

export default FooterStaticSlice;
